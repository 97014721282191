<template>
   <v-container>
      <v-row>
         <v-col cols="12">

            <v-card class="mt-4">
               <v-card-title 
                  class="py-2"
                  style="background-color:#0277BD; color:white;">
                  Cài đặt chiếu sáng
                  <v-spacer/>
                  <v-btn dark text @click="saveSetting"
                     class="text-none">
                     <v-icon class="mr-2">save</v-icon>
                     Lưu
                  </v-btn>
               </v-card-title>
               <v-card-text 
                  v-if="settings"
                  class="pa-4" style="font-size:16px;">

                  <div v-if="1==1">
                     <div class="setting-label">
                        Tự tắt đèn sau
                        <b>{{settings['numOfMinAutoOff-SETTING']}} phút</b>
                     </div>
                     <v-slider class="mt-12"
                        v-model="settings['numOfMinAutoOff-SETTING']"
                        step="5"
                        ticks="always"
                        tick-size="5"
                        :max="60"
                        :min="0"
                        :thumb-size="38"
                        thumb-label="always"
                     >
                        <template v-slot:thumb-label="{ value }">
                           {{value}}ph
                        </template>
                     </v-slider>
                  </div>

                  <div>
                     <div class="setting-label mb-1">
                        Thời gian áp dụng tự tắt đèn
                     </div>
                     <div>
                        <v-btn
                           v-for="(hours, index) in settings['autoOffHours-SETTING']"
                           :key="index"
                           color="primary"
                           class="px-1 ma-1 text-none"
                           style="min-width:40px;"
                           :outlined="!settings['autoOffHours-SETTING'][index]"
                           @click="toggleValue(index)"
                           >
                           {{index}}h
                        </v-btn>
                     </div>
                  </div>

               </v-card-text>
               <v-card-text v-else
                  class="pa-4">
                  Đang kết nối đến thiết bị...
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
export default {
   data () {
      return {
         settings: null,
         // settings: {
         //    'numOfMinAutoOff-SETTING': 60,
         //    'autoOffHours-SETTING': {
         //       0: true,
         //       1: true,
         //       2: true,
         //       3: true,
         //       4: true,
         //       5: true,
         //       6: true,
         //       7: true,
         //       8: true,
         //       9: true,
         //       10: true,
         //       11: true,
         //       12: true,
         //       13: true,
         //       14: true,
         //       15: true,
         //       16: true,
         //       17: false,
         //       18: false,
         //       19: false,
         //       20: false,
         //       21: false,
         //       22: false,
         //       23: false
         //    }
         // },
         socketAskKey: new Date().getTime()
      }
   },
   methods: {
      requestCurrentSetting () {
         this.$socket.emit('getLoggerCurrentSetting', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey
         })
      },
      saveSetting () {
         let settings = {
            'numOfMinAutoOff-SETTING': this.settings['numOfMinAutoOff-SETTING'],
            'autoOffHours-SETTING': JSON.stringify(this.settings['autoOffHours-SETTING'])
         }
         this.$socket.emit('saveChangeSetting', 
            { 
               devCode: this.$root.devCode,
               settings: settings,
               askKey: this.socketAskKey
            })
      },
      toggleValue(index){
         this.settings['autoOffHours-SETTING'][index] = !this.settings['autoOffHours-SETTING'][index]
         this.$forceUpdate()
      }
   },
   sockets: {
      loggerCurrentSetting: function (payload) {
         if(payload && payload.settings && payload.askKey == this.socketAskKey){
            this.settings = payload.settings
            if(payload.settings['numOfMinAutoOff-SETTING']!=undefined){
               this.settings['numOfMinAutoOff-SETTING'] = payload.settings['numOfMinAutoOff-SETTING']
            }
            if(payload.settings['autoOffHours-SETTING']!=undefined){
               this.settings['autoOffHours-SETTING'] = JSON.parse(payload.settings['autoOffHours-SETTING'])
            }
         }
         
         // console.log("loggerCurrentSetting")
         // console.log(this.settings )
      },
      loggerSaveSettingResult (payload) {
         if(payload && payload.askKey == this.socketAskKey) {
            alert("Save " + payload.status?payload.status:'error!')
         }
      }
      
   },
   mounted () {
      this.$nextTick(() => {
         this.requestCurrentSetting()
      })
   }
}
</script>

<style>
   .setting-label {
      font-size: 18px;
   }
</style>